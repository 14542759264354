import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n';
import store from './store/index'
import router from './router'
import firebase from 'firebase/compat/app';


const firebaseConfig = {
  apiKey: "AIzaSyAAKmFi4V3kX-vJZcqlBeYHuK9KQyR-TYE",
  authDomain: "alamalgrp.firebaseapp.com",
  projectId: "alamalgrp",
  storageBucket: "alamalgrp.appspot.com",
  messagingSenderId: "868309229630",
  appId: "1:868309229630:web:721aebca359d303fcef16f",
  measurementId: "G-1KFLNYGMCP"
};

firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  i18n,
  router,

  render: h => h(App)
}).$mount('#app')
