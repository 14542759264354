<template>
  <v-app>
    <v-main>
      <router-view id="main"></router-view>
    </v-main>
    <v-footer padless fixed>
      <v-col class="text-center" cols="12">
        &copy; copyrights {{ new Date().getFullYear() }} —
        <strong>Powered by <a href="https://www.aramtech.ly" target="_blank" class="text-decoration-none">Aram Tech</a></strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      order: "",
    };
  },
};
</script>

<style lang="scss">

@font-face {
  font-family: "Cairo";
  src: local("Cairo"),
   url('./fonts/Cairo-Regular.ttf') format("truetype"),
   url('./fonts/Cairo-ExtraBold.ttf') format("truetype"),
   url('./fonts/Cairo-Bold.ttf') format("truetype"),
   url('./fonts/Cairo-Medium.ttf') format("truetype"),
   url('./fonts/Cairo-SemiBold.ttf') format("truetype"),
   url('./fonts/Cairo-Light.ttf') format("truetype"),
   url('./fonts/Cairo-ExtraLight.ttf') format("truetype"),
   url('./fonts/Cairo-Black.ttf') format("truetype")
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
