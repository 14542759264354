<template>
  <v-container fluid class="pa-0" style="height:100%; background-color:#FFFFFF; overflow-x: hidden;">
    <v-carousel
    cycle
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
  >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
        <v-img
          :src="colors[i]"
          height="97%"
          width="100%"
          contain
        >
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <v-row class="pa-2" justify="center" style="background-color:#262626;">
      <v-col cols="12" class="text-center font-weight-light text-h6 pa-3 mt-3 white--text">SAN Pharma eCatalogue</v-col>
    </v-row>
    <v-row class="pa-2 mt-2" justify="center">
      <v-col cols="6" md="3" xs="6" class="d-flex flex-column justify-center align-center">
          <div class="d-flex flex-no-wrap">
            <div :class="$vuetify.breakpoint.lg ? 'mt-4' : ''">
              <v-card
                elevation="18"
                outlined
                shaped
                @click="catalogue('MADDOX')"
              >
                <v-avatar
                  class="ma-3"
                  :size="$vuetify.breakpoint.lg ? 200 : '140'"
                  tile
                >
                  <v-img
                    src="../../assets/maddox.png"
                    class="white--text align-end"
                    max-width="230"></v-img>
                </v-avatar>  
              </v-card>   
            </div>
          </div>
      </v-col> 
      <v-col cols="6" md="3" xs="6" class=" d-flex flex-column justify-center align-center" >
          <div class="d-flex flex-no-wrap">
            <div :class="$vuetify.breakpoint.lg ? 'mt-4' : ''">
              <v-card
                elevation="18"
                outlined
                shaped
                @click="catalogue('ELAINE PERINE')"
              >
                <v-avatar
                  class="ma-3"
                  :size="$vuetify.breakpoint.lg ? 200 : '140'"
                  tile
                >
                  <v-img
                    src="../../assets/ELP.png"
                    class="white--text align-end"
                    max-width="230"></v-img>
                </v-avatar>  
              </v-card>   
            </div>

          </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {

  data() {
    return {
      colors: [
          'https://res.cloudinary.com/aram-tech/image/upload/v1647215258/Sanpharma/slideshow/s1_sgdwzo.png',
          'https://res.cloudinary.com/aram-tech/image/upload/v1647217787/Sanpharma/slideshow/267111197_292418986156432_588056881681419719_n_x4wx62.jpg',
          'https://res.cloudinary.com/aram-tech/image/upload/v1647216263/Sanpharma/slideshow/s3_h0t02q.png',
          'https://res.cloudinary.com/aram-tech/image/upload/v1647216945/Sanpharma/slideshow/266919670_292418806156450_333332692846199379_n_pb2quu.jpg',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
        ],
    };
  },
  methods: {
      catalogue(brand) {
        this.$store.commit('setFilter', brand)
        this.$router.push({ name: "SAN Pharma Catalogue" });
      }
    
    },
};
</script>
