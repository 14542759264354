<template>
  <v-container fluid style="height:100%; background-color:#262626;">
    <v-row justify="center">
      <v-col cols="12">
         <v-img
          :src="
          require('@/assets/Aram_Icon_Dark.svg')"
          max-width="120"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="12">
        <v-card max-width="550" class="mx-auto">
          <v-card-title class="text-center pa-5 primary white--text">
            <div class="mx-auto">SAN Pharma eCatalogue</div>
          </v-card-title>
          <v-card-text>
            <div class="text-center font-weight-light mb-6 mt-3">
              Please enter password to view eCatalogue... <br/>
              <span class="red--text">{{incorrect}}</span>
            </div>
            <v-form @submit="login">
              <v-text-field
                type="password"
                placeholder="Password..."
                v-model="password"
                @submit="login"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn class="mx-auto primary" large @click="login">Enter</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      incorrect: "",
    };
  },
  methods: {
    login() {
      if (this.password == "sanpharma2022") {
        this.$store.commit('setAuthenticated', true);
        console.log(this.$store.state.authenticated);
        this.$router.push({ name: "SAN Pharma" });
      } else {
        this.incorrect = "The password you have entered is incorrect!"
      }
    },
  },
};
</script>
