<template>
  <v-container fluid style="height:100%; background-color:#262626;">
    <v-row justify="center">
      <v-col :cols="$vuetify.breakpoint.smAndUp ? '12' : '9'">
        <v-img
          :src="require('@/assets/Aram_Icon_Dark.svg')"
          max-width="90"
          :class="$vuetify.breakpoint.smAndUp? 'mx-auto' : 'ml-auto mr-9'"
        />
      </v-col>
      <v-col cols="3">
        <v-btn @click="signOut" class="mx-auto mt-3 error"><v-icon>mdi-logout-variant</v-icon></v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn class="primary" @click="$router.replace({ name: 'Al Amal Group Survey | Register' })" block >Register New User</v-btn>
      </v-col>
      <v-col cols="12">
        <v-card max-width="100%" class="mx-auto">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="2"
            :search="search"
            sort-by="surveys"
            :sort-desc="true"
            :footer-props="{
                disableItemsPerPage: true,
                itemsPerPageText: ' ',
                itemsPerPageOptions: [2,3],
                }"
            class="text-center fontCairo"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Pharmacy",
          value: "pharmacy",
        },
        {
          text: "Supermarket",
          value: "supermarket",
        },
        {
          text: "Cleaning Store",
          value: "cleaningStore",
        },
      ],
      data: [],
    };
  },

  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({ name: "Al Amal Group Survey | Login" });
        });
    },
  },

  created() {
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          this.data.push(doc.data());
        });
      });
  },
};
</script>

<style>
.fontCairo {
  font-family: Cairo !important;
}
</style>
