<template>
  <v-container fluid style="background-color:#00b3e6;">
    <v-app-bar app color="white" elevate-on-scroll>
      <v-toolbar-title
        ><v-img src="../assets/alhakeem.svg" max-width="150"
      /></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="orderList()" style="font-color:red;">
        <v-icon>mdi-basket</v-icon>
        ({{ orderQuantity }})
      </v-btn>
    </v-app-bar>
    <v-row>
      <v-col>
        <v-btn
          :disabled="this.filter == 'All'"
          class="ma-2"
          @click="brandFilter('All')"
          >All</v-btn
        >
        <v-btn
          :disabled="this.filter == 'CeraVe'"
          class="ma-2"
          @click="brandFilter('CeraVe')"
          ><v-img src="../assets/cerave.svg" width="80"></v-img
        ></v-btn>
        <v-btn
          :disabled="this.filter == 'ADVANCIS'"
          class="ma-2"
          @click="brandFilter('ADVANCIS')"
          ><v-img src="../assets/advancis.svg" width="80"></v-img
        ></v-btn>
        <v-btn
          :disabled="this.filter == 'FIDIA'"
          class="ma-2"
          @click="brandFilter('FIDIA')"
          ><v-img src="../assets/fidia.svg" width="80"></v-img
        ></v-btn>
        <v-btn
          :disabled="this.filter == 'Kin'"
          class="ma-2"
          @click="brandFilter('Kin')"
          ><v-img src="../assets/kin.svg" width="80"></v-img
        ></v-btn>
        <v-btn
          :disabled="this.filter == 'La Roche-Posay'"
          class="ma-2"
          @click="brandFilter('La Roche-Posay')"
          ><v-img src="../assets/lprNew.svg" width="80"></v-img
        ></v-btn>

        <v-btn
          :disabled="this.filter == 'VICHY'"
          class="ma-2"
          @click="brandFilter('VICHY')"
          ><v-img src="../assets/vichy.svg" width="80"></v-img
        ></v-btn>
        <v-btn
          :disabled="this.filter == 'UNIMED'"
          class="ma-2"
          @click="brandFilter('UNIMED')"
          >Other</v-btn
        >
      </v-col>
    </v-row>
    <v-row dense>
      <product
        v-bind:key="product.id"
        v-for="product in products"
        v-bind:product="product"
      />
    </v-row>
    <!-- PDF Generation -->
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="Alhakeem Order"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section
          slot="pdf-content"
          id="element-to-print"
          style="font-family:arial;"
        >
          <!-- PDF Content Here -->
          <v-container width="100%" style="height:120px;">
            <v-row>
              <v-col md="6"><img src="../assets/alhakeem.png"/></v-col>
              <v-col md="6" style="line-height:1.5;"
                >Customer Name: <span style="direction:rtl;">{{ name }}</span> <br />
                Company: <span style="direction:rtl;">{{ company }}</span> <br />
                Mobile: {{ phoneNumber }}</v-col
              >
            </v-row>
          </v-container>
          <v-simple-table fixed-header>
            <thead style="background-color:#00b3e6;">
              <tr>
                <td>Company</td>
                <td>Prod. Code</td>
                <td>Product</td>
                <td>Price</td>
                <td>Quantity</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(prd, index) in cart" :key="prd.name">
                <td>{{ prd.name }}</td>
                <td>{{ prd.code }}</td>
                <td>{{ prd.tradename }}</td>
                <td>{{ prd.price }}</td>
                <td>
                  {{ cartQuantity[index] }}
                </td>
              </tr>
              <tr style="border-top: 5px solid #262626;">
                <td colspan="4" style="text-align: right; font-weight:bold;">TOTAL: </td>
                <td style="font-weight:bold;"> {{"LYD " + this.orderTotal }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </section>
      </vue-html2pdf>
    </div>
    <v-dialog v-model="orderlist">
      <v-card>
        <v-container width="100%">
          <v-row>
            <v-col
              ><v-btn color="primary" block @click="orderConfirm()"
                >Confirm Order</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
        <v-simple-table fixed-header>
          <thead style="background-color:#00b3e6;">
            <tr>
              <td>Company</td>
              <td>Product</td>
              <td>Price</td>
              <td>Quantity</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prd, index) in cart" :key="prd.name">
              <td>{{ prd.name }}</td>
              <td>{{ prd.tradename }}</td>
              <td>{{ prd.price }}</td>
              <td>
                {{ cartQuantity[index]
                }}<v-chip
                  class="ma-2"
                  color="red"
                  text-color="white"
                  @click="removePrd(prd.id)"
                  x-small
                >
                  X
                </v-chip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-dialog v-model="download">
      <v-card>
        <v-card-title
          class="text-subtitle-1 white--text"
          style="background-color: #00b3e6;"
          ><p class="mx-auto">Please Enter the required information to <br />
          download your order request.</p></v-card-title
        >
        <v-container class="pa-2">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="Name"
                rules="required"
              >
                <v-text-field
                  v-model="name"
                  :error-messages="errors"
                  label="Name"
                  required
                ></v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Company"
                rules="required"
              >
                <v-text-field
                  v-model="company"
                  :error-messages="errors"
                  label="Company"
                  required
                ></v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="phoneNumber"
                :rules="{
                  required: true,
                  digits: 10,
                  regex: '^(091|092|094|095)\\d{7}$',
                }"
              >
                <v-text-field
                  v-model="phoneNumber"
                  :counter="10"
                  :error-messages="errors"
                  label="Phone Number"
                  required
                ></v-text-field>
              </validation-provider>
              <v-btn
                class="mr-4"
                block
                color="success"
                type="submit"
                :loading="loading"
                :disabled="invalid || loading"
                @click="generateReport()"
              >
                Download Order Request
              </v-btn>
            </form>
          </validation-observer>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="orderSuccess" max-width="260">
      <v-card max-width="260">
        <v-card-title><p class="mx-auto success--text">Success!</p></v-card-title>
        <v-card-text class="black--text">Your order has been sent.</v-card-text>
      </v-card></v-dialog>
  </v-container>
</template>

<script>
import { required, digits, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import html2pdf from "html2pdf.js";
import emailjs from "@emailjs/browser";
import VueHtml2pdf from "vue-html2pdf";
import Product from "@/components/product.vue";
const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/alhakeem-9c2b641bbd3a.json");

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("regex", {
  ...regex,
  message: "{_field_} is not correct",
});

export default {
  components: {
    Product,
    VueHtml2pdf,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      rawProducts: [],
      products: [],
      filter: "All",
      loading: false,
      cart: [],
      quantity: "1",
      cartQuantity: [],
      orderQuantity: "0",
      orderTotal: 0,
      orderlist: false,
      download: false,
      valid: false,
      name: "",
      company: "",
      phoneNumber: "",
      attachment: "",
      orderSuccess: false,
    };
  },
  methods: {

    orderConfirm() {
      this.orderlist = false;
      this.download = true;
    },
    removePrd(prd) {
      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].id == prd) {

          //remove item from invoice total
          this.orderTotal = parseFloat(this.orderTotal)
          let priceSplit = this.cart[i].price.split(' ');
          let intPrice = parseFloat(priceSplit[1]) * parseFloat(this.cartQuantity[i])
          this.orderTotal = this.orderTotal - intPrice
          this.orderTotal = parseFloat(this.orderTotal).toFixed(2)
          console.log(this.orderTotal)

          this.cart.splice(i, 1);
          this.cartQuantity.splice(i, 1);
          this.orderQuantity--;
        }
      }
    },

    orderList() {
      if (this.orderQuantity > 0) this.orderlist = true;
    },

    generateReport() {
      // this.$refs.html2Pdf.generatePdf();
      const self = this
      this.loading = true;
      var element = document.getElementById("element-to-print");
      html2pdf()
        .from(element)
        .toPdf()
        .get("pdf")
        .output("datauristring")
        .then(function(pdfAsString) {
          (this.attachment = pdfAsString), console.log(pdfAsString);
          var templateParams = {
            company: self.company,
            content: this.attachment
          };

          emailjs
            .send(
              "service_pz7xrdj",
              "template_9aaq6dg",
              templateParams,
              "user_yL4gk1FqmdxtlvzuRuQ50"
            )
            .then(
              (result) => {
                console.log("SUCCESS!", result.text, result.status);
                html2pdf().from(element).save().then(() => {
                  self.download = false
                  self.orderSuccess = true
                  self.name = ""
                  self.company = ""
                  self.phoneNumber = ""
                  self.loading = false
                })
              },
              (error) => {
                console.log("FAILED...", error.text, error.status);
              }
            );
        });
    },

    addtoCart(index) {
      this.cart.push(this.rawProducts[index]);
      this.cartQuantity.push(this.quantity);
      console.log(this.cart);
      console.log(this.quantity);

      this.orderQuantity++;

      //calculate item price (item price * quantity and add it to the total)
      this.orderTotal = parseFloat(this.orderTotal)
      let priceSplit = this.rawProducts[index].price.split(' ');
      let intPrice = parseFloat(priceSplit[1]) * parseFloat(this.quantity)
      this.orderTotal = this.orderTotal + intPrice
      this.orderTotal = parseFloat(this.orderTotal).toFixed(2)
      console.log(this.orderTotal)

      //reset quantity field
      this.quantity = "1";    
    },

    brandFilter(brand) {
      this.filter = brand;
      var filtered = [];
      if (brand == "All") {
        this.products = this.rawProducts;
      } else {
        for (let i = 0; i < this.rawProducts.length; i++) {
          if (this.rawProducts[i].name == brand) {
            filtered.push(this.rawProducts[i]);
          }
        }
        this.products = filtered;
      }

      console.log(filtered);
      console.log(this.products);
    },
    async accessSpreadSheet() {
      const doc = new GoogleSpreadsheet(
        "1piQXw0eJKgZU-Cv5LE6paAGoODdGjQF96h-XKlqliy8"
      );
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();
      const sheet = doc.sheetsByIndex[0];
      const rows = await sheet.getRows();
      this.rawProducts = rows;
      this.products = rows;
      console.log(this.filter);
    },
  },

  created() {
    this.accessSpreadSheet();
  },
};
</script>
