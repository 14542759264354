<template>
  <v-col md="4" class="mt-4">
    <v-card width="400" class="mx-auto">
      <v-img
        :src="product.image"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-card-title>{{ product.name }} - {{ product.code }} - {{ product.expiry }}</v-card-title>
      </v-img>

      <v-card-actions>
        <v-card-text>{{product.tradename}}</v-card-text>
        <v-spacer></v-spacer>
        <v-card-text class="ma-0 pa-2 text-left font-weight-bold">
          {{ product.price }} <br/> <span style="color:#00b3e6;">{{ product.pp }}</span>
        </v-card-text>
      
        <v-btn
          elevation="1"
          :color="buttonColor"
          class="white--text"
          @click="dialog = true"
          :disabled="product.available == 'no'"
        >
          Add to Order
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" min-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{product.name}}
        </v-card-title>

        <v-card-text class="text-left" style="direction:rtl;">
          {{product.description}}
        </v-card-text>

        <v-card-text class="text-left">
          <v-text-field label="Quantity" outlined type="number" v-model="$parent.quantity"></v-text-field>
        </v-card-text>
        


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color='buttonColor' text @click="$parent.addtoCart(product.id),dialog = false">
            Add to Order
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: "product",
  props: ["product"],

  data() {
      return {
          dialog: false,
      }
  },
  computed: {
    buttonColor() {
      if(this.$route.name == 'SAN Pharma Catalogue') {
        return "red darken-3"
      }
      else {
        return "#00b3e6"
      }
    }
  }
};
</script>
