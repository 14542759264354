import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        success: "Success!",
        suxMsg: "Your survey has been submitted.",        
        logout: "Logout",
        welcome: "Welcome",
        surveys: "Surveys",
        submit: "Submit",
        next: "Next",
        back: "Back",
        close: "Close",
        step1: "Step 1",
        step2: "Step 2",
        step3: "Step 3",
        step4: "Step 4",
        step5: "Step 5",
        img: "Picture of Front",
        name: "Name",
        address: "Address & Street",
        NearestPoint: "Nearest Point of Refrence",
        area: "Area & Province",
        city: "City",
        gmaps: "Google Maps Link",
        geoLocation: "ADD GEO LOCATION",
        licenseNumber: "License Number",
        type: "Commerical Activity",
        classification: "Classification",
        size: "Size",
        small: "Small",
        medium: "Medium",
        large: "Large",
        hours: "Hours",
        ownerName: "Owner's Name",
        precName: "Precurment Officer's Name",
        phoneNumber: "Phone Number",
        email: "Email",
        manufacturers: "Manufacturers",
        products: "Products",
        dyk: "Do you know AMG?",
        rate: "How would you rate AMG?",
        excellent: "Excellent",
        good: "Good",
        bad: "Bad",
        yes: "Yes",
        no: "No",

    },
    'ar': {
        success: "نجاح!",
        suxMsg: "تم ارسال الاستطلاع الخاص بك",
        logout: "تسجيل خروج",
        welcome: "آهلا",
        surveys: "آستمرات",
        submit: "ارسال",
        next: "التالي",
        back: "ارجع",
        close: "اغلاق",
        step1: "خطوة 1",
        step2: "خطوة 2",
        step3: "خطوة 3",
        step4: "خطوة 4",
        step5: "خطوة 5",
        img: "صورة الواجهة",
        name: "الاسم",
        address: "عنوان الشارع",
        NearestPoint: "اقرب نقطة دالة",
        area: "المنطقة والمقاطعة",
        city: "المدينة",
        gmaps: "رابط خرائط قوقل",
        geoLocation: "أضف الموقع الجغرافي",
        licenseNumber: "رقم الرخصة",
        type: "النشاط التجاري",
        classification: "تصنيف",
        size: "حجم:",
        small: "صغير",
        medium: "متوسط",
        large: "كبير",
        hours: "ساعات العمل",
        ownerName: "أسم المالك",
        precName: "مسؤل المشتريات",
        phoneNumber: "رقم الهاتف",
        email: "البريد الالكتروني",
        manufacturers: "المصنّعين",
        products: "المنتجات",
        dyk: "هل تعرف AMG:",
        rate: "كيف تقيم AMG:",
        excellent: "ممتاز",
        good: "جيد",
        bad: "سيئ",
        yes: "نعم",
        no: "لا",
    }
};

export default new VueI18n({
    locale: 'ar', // set locale
    fallbackLocale: 'ar', // set fallback locale
    messages, // set locale messages
});