<template>
  <v-container fluid style="height:100%; background-color:#262626;">
    <v-row justify="center">
      <v-col cols="12">
        <v-img
          :src="require('@/assets/Aram_Icon_Dark.svg')"
          max-width="120"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-btn @click="changeLocale('en')" text small dark>EN</v-btn>
        <v-btn @click="changeLocale('ar')" text small dark>AR</v-btn>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-row justify="center" class="fontCairo">
          <v-col md="2" class="white--text"
            ><p>{{ $t("welcome") }}, {{ user }}</p></v-col
          >
          <v-col md="2" class="white--text"
            ><p>{{ $t("surveys") }}: {{ surveyCount }}</p></v-col
          >
          <v-col md="2"
            ><v-btn @click="signOut" color="error">{{
              $t("logout")
            }}</v-btn></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" md="6" sm="12" class="pt-0">
        <v-container class="pa-2">
          <v-stepper v-model="e1" class="fontCairo">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                {{ $t("step1") }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                {{ $t("step2") }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3">
                {{ $t("step3") }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 4" step="4">
                {{ $t("step4") }}
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <form>
                    <v-file-input
                      id="img"
                      :label="$t('img')"
                      filled
                      prepend-icon="mdi-camera"
                    ></v-file-input>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="estName"
                        :error-messages="errors"
                        :label="$t('name')"
                        required
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="address"
                        :error-messages="errors"
                        :label="$t('address')"
                        required
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      name="nearestPoint"
                    >
                      <v-text-field
                        v-model="npr"
                        :error-messages="errors"
                        :label="$t('NearestPoint')"
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      name="Area &amp; Province"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="area"
                        :items="locations"
                        :error-messages="errors"
                        filled
                        color="blue-grey lighten-2"
                        :label="$t('area')"
                        item-text="name"
                        item-value="name"
                      ></v-autocomplete>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      name="Link"
                      rules="required"
                    >
                      <v-text-field
                        v-model="gmaps"
                        :error-messages="errors"
                        :label="$t('gmaps')"
                        required
                      ></v-text-field>
                    </validation-provider>

                    <v-btn
                      class="mx-auto mb-4 success"
                      :loading="locationLoading"
                      :disabled="locationAdded"
                      block
                      @click="locateMe"
                      >{{ locationStr }}</v-btn
                    >
                    <v-btn
                      class="ma-2 primary"
                      :disabled="invalid"
                      large
                      @click="e1 = 2"
                      >{{ $t("next") }}</v-btn
                    >
                  </form>
                </validation-observer>
              </v-stepper-content>

              <v-stepper-content step="2">
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <form>
                    <validation-provider
                      v-slot="{ errors }"
                      name="licenseNumber"
                      :rules="{
                        required: false,
                      }"
                    >
                      <v-text-field
                        v-model="licenseNumber"
                        :error-messages="errors"
                        :label="$t('licenseNumber')"
                        required
                        type="number"
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        v-model="type"
                        :error-messages="errors"
                        :items="lang == 'ar' ? type_list_ar : type_list"
                        outlined
                        :label="$t('type')"
                      />
                    </validation-provider>
                    <validation-provider>
                      <v-radio-group v-model="classification" row>
                        <template v-slot:label>
                          <div class="text-h6">{{ $t("classification") }}</div>
                        </template>
                        <v-radio label="A" value="A"></v-radio>
                        <v-radio label="B" value="B"></v-radio>
                        <v-radio label="C" value="C"></v-radio>
                      </v-radio-group>
                    </validation-provider>

                    <validation-provider>
                      <v-radio-group v-model="size" row>
                        <template v-slot:label>
                          <div class="text-h6">{{ $t("size") }}</div>
                        </template>
                        <v-radio :label="$t('small')" value="Small"></v-radio>
                        <v-radio :label="$t('medium')" value="Medium"></v-radio>
                        <v-radio :label="$t('large')" value="Large"></v-radio>
                      </v-radio-group>
                    </validation-provider>

                    <validation-provider>
                      <v-radio-group v-model="hours" row>
                        <template v-slot:label>
                          <div class="text-h6">{{ $t("hours") }}</div>
                        </template>
                        <v-radio label="12" value="12"></v-radio>
                        <v-radio label="24" value="24"></v-radio>
                      </v-radio-group>
                    </validation-provider>

                    <div class="text-center">
                      <v-btn class="ma-2 error" large @click="e1 = 1">{{
                        $t("back")
                      }}</v-btn>
                      <v-btn
                        class="ma-2 primary"
                        :disabled="invalid"
                        large
                        @click="e1 = 3"
                        >{{ $t("next") }}</v-btn
                      >
                    </div>
                  </form>
                </validation-observer>
              </v-stepper-content>

              <v-stepper-content step="3">
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <form>
                    <v-row>
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="precurmentOfficerName"
                          rules="required"
                        >
                          <v-text-field
                            v-model="poName"
                            :error-messages="errors"
                            :label="$t('precName')"
                            required
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="precurmentOfficerPhoneNumber"
                          :rules="{
                            required: true,
                            digits: 10,
                            regex: '^(091|092|094|095)\\d{7}$',
                          }"
                        >
                          <v-text-field
                            v-model="poPhone"
                            :counter="10"
                            :error-messages="errors"
                            :label="$t('phoneNumber')"
                            required
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Email Address"
                          :rules="{
                            required: false,
                            regex: regex.email,
                          }"
                        >
                          <v-text-field
                            v-model="poEmail"
                            :error-messages="errors"
                            :label="$t('email')"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <div class="text-center">
                      <v-btn class="ma-2 error" large @click="e1 = 2">{{
                        $t("back")
                      }}</v-btn>
                      <v-btn
                        class="ma-2 primary"
                        :disabled="invalid"
                        large
                        @click="e1 = 4"
                        >{{ $t("next") }}</v-btn
                      >
                    </div>
                  </form>
                </validation-observer>
              </v-stepper-content>

              <v-stepper-content step="4">
                <validation-observer ref="observer">
                  <form>
                    <validation-provider name="knowAMG" rules="required">
                      <v-radio-group v-model="dykAMG" row>
                        <template v-slot:label>
                          <div class="text-h6">{{ $t("dyk") }}</div>
                        </template>
                        <v-radio :label="$t('yes')" value="Yes"></v-radio>
                        <v-radio :label="$t('no')" value="No"></v-radio>
                      </v-radio-group>
                    </validation-provider>

                    <v-container fluid>
                      <v-checkbox
                        v-for="manufacturer in manufacturersList"
                        :key="manufacturer"
                        :id="manufacturer.id"
                        class="data"
                        v-model="selectedManufactures"
                        :label="manufacturer"
                        :value="manufacturer"
                      ></v-checkbox>
                    </v-container>

                    <validation-provider name="rateAMG" rules="required">
                      <v-radio-group v-model="rateAMG" row>
                        <template v-slot:label>
                          <div class="text-h6">{{ $t("rate") }}</div>
                        </template>
                        <v-radio :label="$t('bad')" value="Bad"></v-radio>
                        <v-radio :label="$t('good')" value="Good"></v-radio>
                        <v-radio
                          :label="$t('excellent')"
                          value="Excellent"
                        ></v-radio>
                      </v-radio-group>
                    </validation-provider>

                    <div class="text-center mt-6 ">
                      <v-btn class="ma-2 error" large @click="e1 = 3">{{
                        $t("back")
                      }}</v-btn>
                      <v-btn
                        class="mx-auto primary"
                        :loading="submitLoading"
                        :disabled="invalid"
                        large
                        @click="accessSpreadSheet"
                        >{{ $t("submit") }}</v-btn
                      >
                    </div>
                  </form>
                </validation-observer>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>
      </v-col>
      <v-col cols="12" class="pt-6"></v-col>
    </v-row>
    <v-dialog v-model="surveySuccess" max-width="260" persistent>
      <v-card max-width="260">
        <v-card-title
          ><p class="mx-auto success--text">
            {{ $t("success") }}
          </p></v-card-title
        >
        <v-card-text class="black--text">{{ $t("suxMsg") }}</v-card-text>
        <v-card-actions
          ><v-btn @click="refresh">{{ $t("close") }}</v-btn></v-card-actions
        >
      </v-card></v-dialog
    >
  </v-container>
</template>

<script>
/* eslint-disable */

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import i18n from "@/plugins/i18n";

import { required, digits, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/alamalgrp-eb9d8bc359e5.json");

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("regex", {
  ...regex,
  message: "{_field_} is not correct",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      regex: {
        url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
        phoneNumber: /^((\+|00)\s?\d{1,3}\s?)?(\(?\d{2,3}\)?)(\-|\s)?(\d{3}(\-|\s)?\d{4})$/,
        name: /^(?:[a-zA-Z]{3,20})(?:\s{1,2}[a-zA-Z]{1,20}){1,3}$/,
        email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
      },

      e1: 1,
      lang: "ar",

      user: "",
      surveyCount: null,

      city_list: ["Tripoli", "Benghazi"],
      city_list_ar: ["طرابلس", "بنغازي"],
      type_list: ["Pharmacy", "Supermarket", "Cleaning Store"],
      type_list_ar: ["صيدلية", "سوبر ماركت", "محل مواد تنظيف"],
      pharmaManufacturers: ["Bayer", "J&J", "Organon", "Eva", "Dar El Dawa"],
      smManufacturers: ["J&J", "Lindt", "Sanytol"],
      csManufacturers: ["J&J", "Sanytol"],

      manufacturersList: [],
      selectedManufactures: [],

      //Survey Data
      estName: "",
      address: "",
      npr: "",
      area: "",
      gmaps: "",
      licenseNumber: "",
      type: "",
      classification: "",
      size: "",
      hours: "",
      poName: "",
      poPhone: "",
      poEmail: "",
      manufacturers: "",
      dykAMG: "",
      rateAMG: "",
      location: "",
      locationLoading: false,
      locationAdded: false,
      locationStr: "Add Geo Location",
      locationURL: "",
      submitLoading: false,
      surveySuccess: false,

      locations: [
        {
          id: 70,
          name: "السياحية",
          parent_city: 1,
        },
        {
          id: 71,
          name: "حي الأندلس",
          parent_city: 1,
        },
        {
          id: 72,
          name: "وسط المدينة",
          parent_city: 1,
        },
        {
          id: 73,
          name: "الظهرة",
          parent_city: 1,
        },
        {
          id: 74,
          name: "زاوية الدهماني",
          parent_city: 1,
        },
        {
          id: 75,
          name: "النوفليين",
          parent_city: 1,
        },
        {
          id: 76,
          name: "بن عاشور",
          parent_city: 1,
        },
        {
          id: 77,
          name: "شارع الجرابة",
          parent_city: 1,
        },
        {
          id: 78,
          name: "شارع الزاوية",
          parent_city: 1,
        },
        {
          id: 79,
          name: "شارع الجمهورية",
          parent_city: 1,
        },
        {
          id: 80,
          name: "شارع النصر",
          parent_city: 1,
        },
        {
          id: 81,
          name: "باب بن غشير",
          parent_city: 1,
        },
        {
          id: 82,
          name: "قرقارش",
          parent_city: 1,
        },
        {
          id: 83,
          name: "غوط الشعال",
          parent_city: 1,
        },
        {
          id: 84,
          name: "السراج",
          parent_city: 1,
        },
        {
          id: 85,
          name: "الدريبي",
          parent_city: 1,
        },
        {
          id: 86,
          name: "قرجي",
          parent_city: 1,
        },
        {
          id: 87,
          name: "قدح",
          parent_city: 1,
        },
        {
          id: 88,
          name: "أبوسليم",
          parent_city: 1,
        },
        {
          id: 89,
          name: "الرياضية",
          parent_city: 1,
        },
        {
          id: 90,
          name: "الفلاح",
          parent_city: 1,
        },
        {
          id: 91,
          name: "غرغور",
          parent_city: 1,
        },
        {
          id: 92,
          name: "حي الزهور",
          parent_city: 1,
        },
        {
          id: 93,
          name: "طريق المطار",
          parent_city: 1,
        },
        {
          id: 94,
          name: "الهضبة",
          parent_city: 1,
        },
        {
          id: 95,
          name: "صلاح الدين",
          parent_city: 1,
        },
        {
          id: 96,
          name: "خلة الفرجان",
          parent_city: 1,
        },
        {
          id: 97,
          name: "طريق الشوك",
          parent_city: 1,
        },
        {
          id: 98,
          name: "الفرناج",
          parent_city: 1,
        },
        {
          id: 99,
          name: "الحي الجامعي",
          parent_city: 1,
        },
        {
          id: 100,
          name: "سيدي المصري",
          parent_city: 1,
        },
        {
          id: 101,
          name: "راس حسن",
          parent_city: 1,
        },
        {
          id: 102,
          name: "زناتة",
          parent_city: 1,
        },
        {
          id: 103,
          name: "جامع الصقع",
          parent_city: 1,
        },
        {
          id: 104,
          name: "الهاني",
          parent_city: 1,
        },
        {
          id: 105,
          name: "عرادة",
          parent_city: 1,
        },
        {
          id: 106,
          name: "الحشان",
          parent_city: 1,
        },
        {
          id: 107,
          name: "سوق الجمعة",
          parent_city: 1,
        },
        {
          id: 108,
          name: "كشلاف",
          parent_city: 1,
        },
        {
          id: 109,
          name: "الغرارات",
          parent_city: 1,
        },
        {
          id: 110,
          name: "بوستة",
          parent_city: 1,
        },
        {
          id: 111,
          name: "طريق الشط",
          parent_city: 1,
        },
        {
          id: 112,
          name: "معيتيقة",
          parent_city: 1,
        },
        {
          id: 113,
          name: "11 يونيو",
          parent_city: 1,
        },
        {
          id: 114,
          name: "السبعة",
          parent_city: 1,
        },
        {
          id: 115,
          name: "طريق المشتل",
          parent_city: 1,
        },
        {
          id: 116,
          name: "حي دمشق",
          parent_city: 1,
        },
        {
          id: 117,
          name: "ولي العهد",
          parent_city: 1,
        },
        {
          id: 118,
          name: "السدرة",
          parent_city: 1,
        },
        {
          id: 119,
          name: "عين زارة",
          parent_city: 1,
        },
        {
          id: 147,
          name: "طريق السور",
          parent_city: 1,
        },
        {
          id: 148,
          name: "بوابة الجبس",
          parent_city: 1,
        },
        {
          id: 149,
          name: "غابة النصر",
          parent_city: 1,
        },
        {
          id: 159,
          name: "شارع الصريم",
          parent_city: 1,
        },
        {
          id: 584,
          name: "جنزور",
          parent_city: 1,
        },
        {
          id: 698,
          name: "فشلوم",
          parent_city: 1,
        },
        {
          id: 700,
          name: "المنصورة",
          parent_city: 1,
        },
        {
          id: 702,
          name: "عمر المختار",
          parent_city: 1,
        },
      ],
    };
  },
  methods: {
    uploadImg() {
      const url = "https://api.cloudinary.com/v1_1/aram-tech/image/upload";

      const img = document.getElementById("img").files;
      const formData = new FormData();

      formData.append("file", img[0]);
      formData.append("upload_preset", "f5onfzbv");

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          let res = response.json({url: response.secure_url});
          console.log(res);
          //console.log(response.secure_url)
        })
        
    },
    refresh() {
      window.location.reload();
    },

    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({ name: "Al Amal Group Survey | Login" });
        });
    },

    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        const options = {
          enableHighAccuracy: true,
        };

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          },
          { enableHighAccuracy: true }
        );
      });
    },

    async locateMe() {
      try {
        (this.locationLoading = true),
          (this.location = await this.getLocation());
        (this.locationLoading = false),
          (this.locationAdded = true),
          (this.locationStr = "Location Added Succesfully!");
        this.locationURL = `http://maps.google.com/?q=${this.location.coords.latitude},${this.location.coords.longitude}`;
        console.log(this.locationURL);
        console.log(this.location.coords.accuracy);
      } catch (e) {
        this.errorStr = e.message;
      }
    },

    async accessSpreadSheet() {
      this.submitLoading = true;

      let manufacturersString = this.selectedManufactures.join(", ");

      const doc = new GoogleSpreadsheet(
        "1ezFSPw3Oq8GSOUINoQCI4HkK5nkUORnwPuME3v8DnFk"
      );
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();

      const sheet = doc.sheetsByTitle[this.user];

      // append rows
      const surveyRow = await sheet.addRow([
        this.user,
        this.surveyCount,
        this.estName,
        this.address,
        this.npr,
        this.area,
        this.gmaps,
        this.locationURL,
        this.licenseNumber,
        this.type,
        this.classification,
        this.size,
        this.hours,
        this.poName,
        this.poPhone,
        this.poEmail,
        manufacturersString,
        this.dykAMG,
        this.rateAMG,
      ]);

      await sheet.saveUpdatedCells();
      await this.addSurvey();

      this.surveySuccess = true;
    },

    async addSurvey() {
      const db = firebase.firestore();
      const increment = firebase.firestore.FieldValue.increment(1);

      const userId = firebase.auth().currentUser.uid;

      // Document reference
      const surveyRef = db.collection("users").doc(userId);

      // Update survey count
      if (this.type == "Pharmacy") {
        await surveyRef.update({ pharmacy: increment });
      } else if (this.type == "Supermarket") {
        await surveyRef.update({ supermarket: increment });
      } else {
        await surveyRef.update({ cleaningStore: increment });
      }

      await surveyRef.update({ surveys: increment });

      console.log("Survey added");
    },

    changeLocale(locale) {
      i18n.locale = locale;
      if (locale == "en") {
        this.$vuetify.rtl = false;
      } else {
        this.$vuetify.rtl = true;
      }
      this.lang = locale;
    },

    addProduct(item) {
      this.selectedProducts.push(item);
      console.log(this.selectedProducts);

      if (!this.selectedManufactures.includes(this.selectedManufacture)) {
        //only runs if value not in array
        this.selectedManufactures.push(this.selectedManufacture);
        console.log(this.selectedManufactures);
      }
    },
  },

  created() {
    if (this.lang == "ar") this.$vuetify.rtl = true;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            console.log(snapshot.data().name);
            this.user = snapshot.data().name;
            this.surveyCount = snapshot.data().surveys;
          });
      }
    });
  },

  watch: {
    type: function() {
      // Clear previously selected values
      this.manufacturersList = [];

      if (this.type.length > 0) {
        if (this.type == "صيدلية") {
          this.manufacturersList = this.pharmaManufacturers;
        } else if (this.type == "سوبر ماركت") {
          this.manufacturersList = this.smManufacturers;
        } else this.manufacturersList = this.csManufacturers;
      }
    },
  },
};
</script>
<style lang="scss">
:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

.fontCairo {
  font-family: Cairo !important;
}

select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

.select {
  max-width: 100% !important;
  margin-top: 1% !important;
  margin-bottom: 0.5% !important;
  margin: auto;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  min-width: 15ch;
  max-width: 30ch;

  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;

  // Optional styles
  // remove for transparency
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}

// Interim solution until :focus-within has better support
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

select[multiple] {
  padding-right: 0;

  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;

  option {
    white-space: normal;

    // Only affects Chrome
    outline-color: var(--select-focus);
  }

  /* 
   * Experimental - styling of selected options
   * in the multiselect
   * Not supported crossbrowser
   */
  //   &:not(:disabled) option {
  //     border-radius: 12px;
  //     transition: 120ms all ease-in;

  //     &:checked {
  //       background: linear-gradient(hsl(242, 61%, 76%), hsl(242, 61%, 71%));
  //       padding-left: 0.5em;
  //       color: black !important;
  //     }
  //   }
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

label {
  font-size: 1.125rem;
  font-weight: 500;
}

.select + label {
  margin-top: 2rem;
}
</style>
