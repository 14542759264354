<template>
  <v-container fluid style="height:100%; background-color:#262626;">
    <v-row justify="center">
      <v-col cols="12">
         <v-img
          :src="
          require('@/assets/Aram_Icon_Dark.svg')"
          max-width="120"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
          <v-row justify="center">
              <v-col md="2"><v-btn @click="signOut" color="error">Logout</v-btn></v-col>
          </v-row>
      </v-col>
      <v-col cols="12">
        <v-card max-width="550" class="mx-auto">
          <v-card-title class="text-center pa-5 primary white--text">
            <div class="mx-auto">Al Amal Group Survey</div>
          </v-card-title>
          <v-card-text>
            <div class="text-center font-weight-light mb-6 mt-3">
              Register a new user... <br/>
              <span class="red--text">{{incorrect}}</span>
            </div>
            <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form @submit="register">
               <validation-provider
                   v-slot="{ errors }"
                   name="Name"
                   rules="required"
               >
              <v-text-field
                type="text"
                :error-messages="errors"
                label="Name"
                prepend-icon="mdi-account"
                v-model="name"
              />
               </validation-provider>

               <validation-provider
                    v-slot="{ errors }"
                    name="Email Address"
                    :rules="{
                          required: true,
                          regex: regex.email
                       }"
                 >

              <v-text-field
                type="email"
                :error-messages="errors"
                label="Email"
                prepend-icon="mdi-email"
                v-model="email"
              /> </validation-provider>



            <validation-provider :rules="{ required: true, regex: regex.password}">
             <v-text-field
             v-model="password"
             label="Password"
             prepend-icon="mdi-lock-outline"
             type="password"
             :rules="[required, min6]"
             
            >
            <template v-slot:append>
              <v-icon
                 v-if="successPass"
                 color="green"
                 >{{ passRule }}</v-icon
                >
                <v-icon
                 v-if="!successPass"
                 color="red"
                 >{{ passRule }}</v-icon
                >
                  </template>
             </v-text-field>
            </validation-provider>
                

              
              <v-text-field
             v-model="password1"
             label="Verify Password"
             prepend-icon="mdi-lock-outline"
             type="password"
             :rules="[required, min6, matchingPasswords ]"
             
            >
            <template v-slot:append>
              <v-icon
                v-if="successPass1"
                color="green"
                >{{ passRule1 }}</v-icon
              >
              <v-icon
                 v-if="!successPass1"
                 color="red"
                 >{{ passRule1 }}</v-icon
              >
            </template>
          </v-text-field>




            </v-form>
            <v-btn class="mx-auto primary" :disabled="invalid" :loading="loading"  @click="register" large>Register</v-btn>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>    
    <v-dialog v-model="successDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 success--text">
          <p class="mx-auto">Success</p>
        </v-card-title>

        <v-card-text class="mt-3">
          {{name}} was successfully registered!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$router.replace({ name: 'Al Amal Group Survey | Admin' })"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
 /* eslint-disable */
 const firebaseConfig = {
  apiKey: "AIzaSyAAKmFi4V3kX-vJZcqlBeYHuK9KQyR-TYE",
  authDomain: "alamalgrp.firebaseapp.com",
  projectId: "alamalgrp",
  storageBucket: "alamalgrp.appspot.com",
  messagingSenderId: "868309229630",
  appId: "1:868309229630:web:721aebca359d303fcef16f",
  measurementId: "G-1KFLNYGMCP"
};

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


import { required, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  
} from "vee-validate";

const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/alamalgrp-eb9d8bc359e5.json");

setInteractionMode("eager");


extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});
extend("regex", {
  ...regex,
  message: "{_field_} is not correct",
});

export default {

   components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {

    return {
      name: "",
      email: "",
      incorrect: "",
      uid: "",
      successDialog: false,
      password: '',
      password1: '',
      successPass: false,
      successPass1: false,

      regex: {
        url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
        name: /^(?:[a-zA-Z]{3,20})(?:\s{1,2}[a-zA-Z]{1,20}){1,3}$/,
        password: /^.{6,26}$/,
        email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
      },

      loading: false, 
    };
    
   

  },
  

  computed: {
    passRule: function() {
      if (this.password === '') {
        // field is empty
        this.successPass = false;
        return '';
      } else if (this.min6(this.password) === true) {
        // password ok
        this.successPass = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass = false;
        return 'mdi-close';
      }
    },
    passRule1: function() {
      if (this.password1 === '') {
        // field is empty
        this.successPass1 = false;
        return '';
      } else if (this.min6(this.password1) === true && this.matchingPasswords() === true) {
        // password ok
        this.successPass1 = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass1 = false;
        return 'mdi-close';
      }
    },
  },

  

  methods: {

    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({ name: "Al Amal Group Survey | Login" });
        });
    },

        required: function(value) {
      if (value) {
        return true;
      } else {
        return 'This field is required.';
      }
    },
    min6: function(value) {
      if (value.length >= 6) {
        return true;
      } else {
        return 'Password should have more than 6 characters.';
      }
    },
    matchingPasswords: function() {
      if (this.password === this.password1) {
        return true;
      } else {
        return 'Passwords does not match.';
      }
    },


    reloadPage() {
      window.location.reload();
    },

    async accessSpreadSheet() {
      
      const doc = new GoogleSpreadsheet(
        "1ezFSPw3Oq8GSOUINoQCI4HkK5nkUORnwPuME3v8DnFk"
      );
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();
      
      const sheet = await doc.addSheet({ title: this.name, headerValues: [
          'User',
          'Survey #',
          'Name',
          'Address/Street',
          'NPR',
          'Area/Province',
          'Google Maps Link',
          'Geo Location',
          'License #',
          'Commerical Activity',
          'Classification',
          'Size m2',
          'Hours',
          "Prec. Officer Name",
          "Prec. Officer Phone",
          "Prec. Officer Email",
          'Manufacturers',
          'DYK AMG',
          'Rate AMG'
          ]});

          sheet.updateDimensionProperties("COLUMNS", {pixelSize: 140})
          sheet.updateDimensionProperties("ROWS", {pixelSize: 60})

      await sheet.loadCells('A1:W1');
      //get cells A1:Q1
      const cellA1 = sheet.getCellByA1('A1')
      const cellB1 = sheet.getCellByA1('B1')
      const cellC1 = sheet.getCellByA1('C1')
      const cellD1 = sheet.getCellByA1('D1')
      const cellE1 = sheet.getCellByA1('E1')
      const cellF1 = sheet.getCellByA1('F1')
      const cellG1 = sheet.getCellByA1('G1')
      const cellH1 = sheet.getCellByA1('H1')
      const cellI1 = sheet.getCellByA1('I1')
      const cellJ1 = sheet.getCellByA1('J1')
      const cellK1 = sheet.getCellByA1('K1')
      const cellL1 = sheet.getCellByA1('L1')
      const cellM1 = sheet.getCellByA1('M1')
      const cellN1 = sheet.getCellByA1('N1')
      const cellO1 = sheet.getCellByA1('O1')
      const cellP1 = sheet.getCellByA1('P1')
      const cellQ1 = sheet.getCellByA1('Q1')
      const cellR1 = sheet.getCellByA1('R1')
      const cellS1 = sheet.getCellByA1('S1')

      //CHANGE BG COLOR
      cellA1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellB1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellC1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellD1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellE1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellF1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellG1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellH1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellI1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellJ1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellK1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellL1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellM1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellN1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellO1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellP1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellQ1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellR1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}
      cellS1.backgroundColor = {red: 0.02352941, green: 0.29803922, blue: 0.5254902}

      //TEXT FORMAT
      cellA1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellB1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellC1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellD1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellE1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellF1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellG1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellH1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellI1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellJ1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellK1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellL1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellM1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellN1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellO1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellP1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellQ1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellR1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
      cellS1.textFormat = { fontSize: 12, foregroundColor: {red: 1, green: 1, blue: 1}}
    
      cellA1.horizontalAlignment = "CENTER"
      cellB1.horizontalAlignment = "CENTER"
      cellC1.horizontalAlignment = "CENTER"
      cellD1.horizontalAlignment = "CENTER"
      cellE1.horizontalAlignment = "CENTER"
      cellF1.horizontalAlignment = "CENTER"
      cellG1.horizontalAlignment = "CENTER"
      cellH1.horizontalAlignment = "CENTER"
      cellI1.horizontalAlignment = "CENTER"
      cellJ1.horizontalAlignment = "CENTER"
      cellK1.horizontalAlignment = "CENTER"
      cellL1.horizontalAlignment = "CENTER"
      cellM1.horizontalAlignment = "CENTER"
      cellN1.horizontalAlignment = "CENTER"
      cellO1.horizontalAlignment = "CENTER"
      cellP1.horizontalAlignment = "CENTER"
      cellQ1.horizontalAlignment = "CENTER"
      cellR1.horizontalAlignment = "CENTER"
      cellS1.horizontalAlignment = "CENTER"

      cellA1.verticalAlignment = "MIDDLE"
      cellB1.verticalAlignment = "MIDDLE"
      cellC1.verticalAlignment = "MIDDLE"
      cellD1.verticalAlignment = "MIDDLE"
      cellE1.verticalAlignment = "MIDDLE"
      cellF1.verticalAlignment = "MIDDLE"
      cellG1.verticalAlignment = "MIDDLE"
      cellH1.verticalAlignment = "MIDDLE"
      cellI1.verticalAlignment = "MIDDLE"
      cellJ1.verticalAlignment = "MIDDLE"
      cellK1.verticalAlignment = "MIDDLE"
      cellL1.verticalAlignment = "MIDDLE"
      cellM1.verticalAlignment = "MIDDLE"
      cellN1.verticalAlignment = "MIDDLE"
      cellO1.verticalAlignment = "MIDDLE"
      cellP1.verticalAlignment = "MIDDLE"
      cellQ1.verticalAlignment = "MIDDLE"
      cellR1.verticalAlignment = "MIDDLE"
      cellS1.verticalAlignment = "MIDDLE"

      await sheet.saveUpdatedCells();
      console.log('Sheet Added!')
      this.successDialog = true;

    },

    register() {
      this.loading = true;

      let firebaseAdmin = firebase.initializeApp(firebaseConfig, 'firebaseAdmin');
      let firebaseAdminAuth = firebase.auth(firebaseAdmin);
      firebaseAdminAuth.setPersistence(firebase.auth.Auth.Persistence.NONE);
      
      firebaseAdminAuth
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((result) => {
          console.log("Registered:", result.user.uid);

          const db = firebase.firestore();

          // Add a new document in collection "users"
          db.collection("users").doc(result.user.uid).set({
              name: this.name,
              surveys: 0,
              pharmacy: 0,
              supermarket: 0,
              cleaningStore: 0,

          })
          .then(() => {
              console.log("Document successfully written!");
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
          });
            })
            .catch(error => (this.error = error));

      this.accessSpreadSheet();                       
            
    },

  },
};
</script>

<style>
.fontCairo {
  font-family: Cairo !important;
}
</style>
