// Vue
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authenticated: false,
    filter: "All",
  },
  mutations: {
    setAuthenticated(state, status) {
      state.authenticated = status;
    },
    setFilter(state, status) {
      state.filter = status;
    },
  },
  getters: {
    filter (state) {
      return state.filter
    },
  }
});

export default store;
