<template>
  <v-container fluid style="height:100%; background-color:#ffff;" class="fontCairo ">
    <v-row class="blue darken-4">
      <v-col>
         <v-img class="mx-auto" src="../../assets/sanpharma_white.svg" max-width="180"/>
      </v-col>
    </v-row>

    <v-row justify="center" class="mar0 fontCairo ">
      <v-carousel
        style="margin-top: 5%"
        cycle
        height="200"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
          <v-img :src="colors[i]" height="97%" width="100%" contain> </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-row>

    <v-row>
      <v-col>
        <p class="pharmalocation">Pharmacy Locations</p>
      </v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>

    <!-- ///////////////////////////////////////////////////// -->

    <v-row justify="center">
      <v-col md="2">
        <div class="text-center">
          <v-dialog v-model="tripoli" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                class="button"
              >
                طرابلس
              </v-btn>
            </template>

            <v-card class="fontCairo">
              <v-card-title class="text-h5 blue darken-4 text-center">
                <div :style="{ color: ' #ffff' }">Tripoli Locations</div>
              </v-card-title>
              <v-layout column style="height: 50vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="headers"
                    :items="tripLocations"
                    :items-per-page="100"
                    hide-default-footer
                    :loading="!tripLocations.length"
                    loading-text="جاري التحميل"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="tripoli = false">
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>

      <v-col md="2">
        <div class="text-center">
          <v-dialog v-model="benghazi" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                class="button"
              >
                بنغازي
              </v-btn>
            </template>

            <v-card class="fontCairo">
              <v-card-title class="text-h5 blue darken-4 text-center">
                <div :style="{ color: ' #ffff' }">Benghazi Locations</div>
              </v-card-title>

              <v-layout column style="height: 50vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="headers"
                    :items="bengLocations"
                    :items-per-page="100"
                    hide-default-footer
                    :loading="!bengLocations.length"
                    loading-text="جاري التحميل"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="benghazi = false">
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="2">
        <div class="text-center">
          <v-dialog v-model="misurata" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                class="button"
              >
                مصراته
              </v-btn>
            </template>

            <v-card class="fontCairo">
              <v-card-title class="text-h5 blue darken-4 text-center">
                <div :style="{ color: ' #ffff' }">Misurata Locations</div>
              </v-card-title>

              <v-layout column style="height: 50vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="headers"
                    :items="misrLocations"
                    :items-per-page="100"
                    hide-default-footer
                    :loading="!misrLocations.length"
                    loading-text="جاري التحميل"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="misurata = false">
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>

      <!-- ///////////////////////////////////////////// -->

      <v-col md="2">
        <div class="text-center">
          <v-dialog v-model="sebha" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                class="button"
              >
                سبها
              </v-btn>
            </template>

            <v-card class="fontCairo">
              <v-card-title class="text-h5 blue darken-4 text-center">
                <div :style="{ color: ' #ffff' }">Sebha Locations</div>
              </v-card-title>
              <v-layout column style="height: 50vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="headers"
                    :items="sebhaLocations"
                    :items-per-page="100"
                    hide-default-footer
                    :loading="!sebhaLocations.length"
                    loading-text="جاري التحميل"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="sebha = false">
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="2">
        <div class="text-center">
          <v-dialog v-model="zawia" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                class="button"
              >
                الزاوية
              </v-btn>
            </template>

            <v-card class="fontCairo">
              <v-card-title class="text-h5 blue darken-4 text-center">
                <div :style="{ color: ' #ffff' }">Zawia Locations</div>
              </v-card-title>
              <v-layout column style="height: 50vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="headers"
                    :items="zawiaLocations"
                    :items-per-page="100"
                    hide-default-footer
                    :loading="!zawiaLocations.length"
                    loading-text="جاري التحميل"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="zawia = false">
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>

      <v-col md="2">
        <div class="text-center">
          <v-dialog v-model="zliten" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                class="button"
              >
                زليتن
              </v-btn>
            </template>

            <v-card class="fontCairo">
              <v-card-title class="text-h5 blue darken-4 text-center">
                <div :style="{ color: ' #ffff' }">Zliten Locations</div>
              </v-card-title>
              <v-layout column style="height: 50vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="headers"
                    :items="zltnLocations"
                    :items-per-page="100"
                    hide-default-footer
                    :loading="!zltnLocations.length"
                    loading-text="جاري التحميل"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="zliten = false">
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/sanpharma-e2e967a7ec6f.json");

export default {
  data() {
    return {
      tripLocations: [],
      bengLocations: [],
      misrLocations: [],
      sebhaLocations: [],
      zawiaLocations: [],
      zltnLocations: [],

      tripoli: false,
      benghazi: false,
      misurata: false,
      sebha: false,
      zawia: false,
      zliten: false,

      headers: [
        {
          text: "الاسم",
          align: "start",

          value: "name",
        },
        { text: "المنطقة", value: "location" },
      ],

      colors: [
        "https://res.cloudinary.com/aram-tech/image/upload/v1647215258/Sanpharma/slideshow/s1_sgdwzo.png",
        "https://res.cloudinary.com/aram-tech/image/upload/v1647217787/Sanpharma/slideshow/267111197_292418986156432_588056881681419719_n_x4wx62.jpg",
        "https://res.cloudinary.com/aram-tech/image/upload/v1647216263/Sanpharma/slideshow/s3_h0t02q.png",
        "https://res.cloudinary.com/aram-tech/image/upload/v1647216945/Sanpharma/slideshow/266919670_292418806156450_333332692846199379_n_pb2quu.jpg",
      ],
      slides: ["First", "Second", "Third", "Fourth"],
    };
  },
  methods: {
    async accessSpreadSheet() {
      const doc = new GoogleSpreadsheet(
        "1KCWFKRUyisGev3gNnqq57k0HSO3amrDafDme3u0jdwI"
      );
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();

      const trip = doc.sheetsByIndex[0];
      const tripRows = await trip.getRows();
      this.tripLocations = tripRows;

      const beng = doc.sheetsByIndex[1];
      const bengRows = await beng.getRows();
      this.bengLocations = bengRows;

      const misr = doc.sheetsByIndex[2];
      const misrRows = await misr.getRows();
      this.misrLocations = misrRows;

      const sebha = doc.sheetsByIndex[3];
      const sebhaRows = await sebha.getRows();
      this.sebhaLocations = sebhaRows;

      const zawia = doc.sheetsByIndex[4];
      const zawiaRows = await zawia.getRows();
      this.zawiaLocations = zawiaRows;

      const zltn = doc.sheetsByIndex[4];
      const zltnRows = await zltn.getRows();
      this.zltnLocations = zltnRows;
    },
  },

  created() {
    this.accessSpreadSheet();
    this.$vuetify.rtl = true;
  },
};
</script>
<style>

.fontCairo {
  font-family: Cairo !important;
}

.mar0 {
  margin-top: 0% !important;
}

.Sanpharma {
  font-size: 30px;
  font-weight: bold;
  color: white;
}
.Sanprow {
  background-color: #fe1716 !important;
}

.pharmalocation {
  font-size: 22px;
  font-weight: bold;
}
.pharma {
  text-align: center;
  margin: auto;
}
.centered {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.button {
  min-height: 46px !important;
  max-height: 47px !important;
  min-width: 170px !important;
  max-width: 175px !important;
}
.ma {
  margin-top: 5% !important;
}
</style>
