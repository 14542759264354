<template>
  <v-container fluid style="background-color:#2F3640; height:100%">
    <v-row>
      <v-col md="12">
        <v-img
          src="../assets/Aram_Icon_Dark.svg"
          style="margin-left:auto; margin-right:auto;"
          max-width="500"
        ></v-img>
      </v-col>
    </v-row>
    <v-row style="color:#F8F8FF;">
      <v-col md="12" style="text-align:center;">
        <h1>Aram Tech's QR Solutions</h1>
        <h3>
          <br />Providing progressive and innovative solutions for your business
          <br /><br />
          Making it all easily accessible with the new and trending QR code
          technology.
        </h3>
        <p>
        <a href="https://www.facebook.com/aramtechly" style="padding-right:3%;">
            <img
              src="https://img.icons8.com/color/36/000000/facebook-new.png"
            />
            <span style="vertical-align:50%; color:white; text-decoration:underline;"> Aram Tech</span>
          </a>
        <a href="https://wa.me/message/J66FX6PQQDNTD1"  style="padding-left:3%;">
            <img
              src="https://img.icons8.com/color/36/000000/whatsapp--v1.png"
            />
            <span style="vertical-align:50%; color:white; text-decoration:underline;"> +218-911102333</span>
          </a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
