import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import eCatalogue from '../views/ecatalogue.vue'
import Alhakeem from '../views/Alhakeem.vue'
import AlhakeemLogin from '../views/alhakeem_mail/loginAlhakeem.vue'
import AlhakeemMail from '../views/alhakeem_mail/mailAlhakeem.vue'
import authSan from '../views/sanpharma/Authsan.vue'
import Sanpharma from '../views/sanpharma/Sanpharma.vue'
import Catalogue from '../views/sanpharma/Catalogue.vue'
import amgsurv from '../views/AMG/amgSurvey.vue'
import QR_pharma from '../views/sanpharma/QR_pharma.vue'
import amgsurvLogin from '../views/AMG/loginAmg.vue'
import amgsurvAdmin from '../views/AMG/admin.vue'
import amgsurvRegister from '../views/AMG/registerAmg.vue'
import store from '../store/index'

import alhakeemFirebase from '../alhakeemFirebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


Vue.use(VueRouter)

const routes = [

  /////////////// eCatalogue Demo /////////////////
  {
    path: '/ecatalogue',
    name: 'eCatalogue',
    component: eCatalogue,
  },

  /////////////// Al Hakeem ROUTING ///////////////
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/alhakeem',
    name: 'Alhakeem',
    component: Alhakeem,
  },
  {
    path: '/alhakeem/login',
    name: 'Alhakeem Login',
    component: AlhakeemLogin,
  },
  {
    path: '/alhakeem/mail',
    name: 'Alhakeem Mail App',
    component: AlhakeemMail,

    beforeEnter: ((to, from, next) => {

      alhakeemFirebase.auth().onAuthStateChanged(userAuth => {
    
        if (userAuth) {
          alhakeemFirebase.auth().currentUser.getIdTokenResult()
            .then(function () {
                return next()    
            })
        } else {
            next({
              path: '/alhakeem/login',
              query: {
                redirect: to.fullPath
              }
            })
        }
    
      })  
    })
  },

  /////////////// SAN Pharma ROUTING ///////////////
  {
    path: '/authsan',
    name: 'Login',
    component: authSan,
  },

  {
    path: '/sanpharma',
    name: 'SAN Pharma',
    component: Sanpharma,
    beforeEnter: (to, from, next) => {
      if(store.state.authenticated == false) {
        next("/authsan")
      }
      next()
    }
  },
  {
    path: '/sanpharma/catalogue',
    name: 'SAN Pharma Catalogue',
    component: Catalogue,
    beforeEnter: (to, from, next) => {
      if(store.state.authenticated == false) {
        next("/authsan")
      }
      next()
    }
  },
  {
    path: '/qr_pharma',
    name: 'Sanpharma Pharmacy Location',
    component: QR_pharma,

  },

  /////////////// AMG ROUTING ///////////////
  {
    path: '/amgsurv',
    name: 'Al Amal Group Survey',
    component: amgsurv,

    beforeEnter: ((to, from, next) => {

      firebase.auth().onAuthStateChanged(userAuth => {
    
        if (userAuth) {
          firebase.auth().currentUser.getIdTokenResult()
            .then(function ({
              claims
            }) {
    
              if (claims.user_id == "YnLT2hICIZWJAIYxJmlL9BbSj042") {
                if (to.path !== '/amgsurv/admin')
                  return next({
                    path: '/amgsurv/admin',
                  })
              }
              else {
                return next()
              }
    
            })
        } else {
            next({
              path: '/amgsurv/login',
              query: {
                redirect: to.fullPath
              }
            })
        }
    
      })  
    })
  },
  {
    path: '/amgsurv/login',
    name: 'Al Amal Group Survey | Login',
    component: amgsurvLogin,
  },
  {
    path: '/amgsurv/admin',
    name: 'Al Amal Group Survey | Admin',
    component: amgsurvAdmin,

    beforeEnter: ((to, from, next) => {

      firebase.auth().onAuthStateChanged(userAuth => {
    
        if (userAuth) {
          firebase.auth().currentUser.getIdTokenResult()
            .then(function ({
              claims
            }) {
    
              if (claims.user_id !== "YnLT2hICIZWJAIYxJmlL9BbSj042") {
                if (to.path !== '/amgsurv')
                  return next({
                    path: '/amgsurv',
                  })
              }
              else {
                return next()
              }
    
            })
        } else {
            next({
              path: '/amgsurv/login',
              query: {
                redirect: to.fullPath
              }
            })
        }
    
      })  
    })
  },
  {
    path: '/amgsurv/register',
    name: 'Al Amal Group Survey | Register',
    component: amgsurvRegister,

    beforeEnter: ((to, from, next) => {

      firebase.auth().onAuthStateChanged(userAuth => {
    
        if (userAuth) {
          firebase.auth().currentUser.getIdTokenResult()
            .then(function ({
              claims
            }) {
    
              if (claims.user_id !== "YnLT2hICIZWJAIYxJmlL9BbSj042") {
                if (to.path !== '/amgsurv')
                  return next({
                    path: '/amgsurv',
                  })
              }
              else {
                return next()
              }
    
            })
        } else {
            next({
              path: '/amgsurv/login',
              query: {
                redirect: to.fullPath
              }
            })
        }
    
      })  
    })
  },
  

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}` 
  next()
})

export default router
