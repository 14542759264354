<template>
  <v-container fluid style="height:100%; background-color:#262626;">
    <v-row justify="center">
      <v-col cols="12">
         <v-img
          :src="
          require('@/assets/Aram_Icon_Dark.svg')"
          max-width="120"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="12">
        <v-card max-width="550" class="mx-auto">
          <v-card-title class="text-center pa-5 primary white--text">
            <div class="mx-auto">Al Amal Group Survey</div>
          </v-card-title>
          <v-card-text>
            <div class="text-center font-weight-light mb-6 mt-3">
              Please login to view Survey... <br/>
              <span class="red--text">{{incorrect}}</span>
            </div>
            <v-form @submit="login">
              <v-text-field
                type="email"
                label="Email"
                prepend-icon="mdi-email"
                v-model="email"
              />
              <v-text-field
                type="password"
                label="Password"
                prepend-icon="mdi-lock-outline"
                v-model="password"
                @submit="login"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn class="mx-auto primary" :loading="loading"  @click="login" large>Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="errorDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 error--text">
          <p class="mx-auto">There seems to be a problem</p>
        </v-card-title>

        <v-card-text class="mt-3">
          {{error}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="reloadPage"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
  data() {
    return {
      email: "",
      password: "",
      incorrect: "",
      loading: false,
      errorDialog: false,
      error: "",
    };
  },
  created() {
        firebase.auth().onAuthStateChanged(userAuth => {
            if (userAuth) {
                firebase
                    .auth()
                    .currentUser.getIdTokenResult()
                    .then(tokenResult => {
                        console.log(tokenResult.claims);
                    });
            }
        });
    },

    methods: {
        login() {
          this.loading = true;
          firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password)
            .then(data => {
              console.log(data);
              this.$router.replace({ name: "Al Amal Group Survey" });
            })
            .catch(error => {
              this.error = error;
              this.errorDialog = true;

            });
        },

      reloadPage() {
        window.location.reload();
      },
    }
};
</script>

<style>
.fontCairo {
  font-family: Cairo !important;
}
</style>
