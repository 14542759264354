import firebase from 'firebase/compat/app';

const alhakeemFirebaseConfig = {
    apiKey: "AIzaSyDsISGrAOu_kjWfapmrdnml0Mu9QkdLx5E",
    authDomain: "alhakeem-6ad43.firebaseapp.com",
    projectId: "alhakeem-6ad43",
    storageBucket: "alhakeem-6ad43.appspot.com",
    messagingSenderId: "869355114370",
    appId: "1:869355114370:web:527835e8d3479784ff899b"
  }
  
  const alhakeemFirebase = firebase.initializeApp(alhakeemFirebaseConfig, "alhakeem")

  export default alhakeemFirebase