var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fontCairo ",staticStyle:{"height":"100%","background-color":"#ffff"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"blue darken-4"},[_c('v-col',[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("../../assets/sanpharma_white.svg"),"max-width":"180"}})],1)],1),_c('v-row',{staticClass:"mar0 fontCairo ",attrs:{"justify":"center"}},[_c('v-carousel',{staticStyle:{"margin-top":"5%"},attrs:{"cycle":"","height":"200","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.slides),function(slide,i){return _c('v-carousel-item',{key:i},[_c('v-img',{attrs:{"src":_vm.colors[i],"height":"97%","width":"100%","contain":""}})],1)}),1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"pharmalocation"},[_vm._v("Pharmacy Locations")])])],1),_c('v-divider',{staticClass:"mb-5"}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"color":"blue darken-4","dark":""}},'v-btn',attrs,false),on),[_vm._v(" طرابلس ")])]}}]),model:{value:(_vm.tripoli),callback:function ($$v) {_vm.tripoli=$$v},expression:"tripoli"}},[_c('v-card',{staticClass:"fontCairo"},[_c('v-card-title',{staticClass:"text-h5 blue darken-4 text-center"},[_c('div',{style:({ color: ' #ffff' })},[_vm._v("Tripoli Locations")])]),_c('v-layout',{staticStyle:{"height":"50vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tripLocations,"items-per-page":100,"hide-default-footer":"","loading":!_vm.tripLocations.length,"loading-text":"جاري التحميل"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.tripoli = false}}},[_vm._v(" Done ")])],1)],1)],1)],1)]),_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"color":"blue darken-4","dark":""}},'v-btn',attrs,false),on),[_vm._v(" بنغازي ")])]}}]),model:{value:(_vm.benghazi),callback:function ($$v) {_vm.benghazi=$$v},expression:"benghazi"}},[_c('v-card',{staticClass:"fontCairo"},[_c('v-card-title',{staticClass:"text-h5 blue darken-4 text-center"},[_c('div',{style:({ color: ' #ffff' })},[_vm._v("Benghazi Locations")])]),_c('v-layout',{staticStyle:{"height":"50vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.bengLocations,"items-per-page":100,"hide-default-footer":"","loading":!_vm.bengLocations.length,"loading-text":"جاري التحميل"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.benghazi = false}}},[_vm._v(" Done ")])],1)],1)],1)],1)])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"color":"blue darken-4","dark":""}},'v-btn',attrs,false),on),[_vm._v(" مصراته ")])]}}]),model:{value:(_vm.misurata),callback:function ($$v) {_vm.misurata=$$v},expression:"misurata"}},[_c('v-card',{staticClass:"fontCairo"},[_c('v-card-title',{staticClass:"text-h5 blue darken-4 text-center"},[_c('div',{style:({ color: ' #ffff' })},[_vm._v("Misurata Locations")])]),_c('v-layout',{staticStyle:{"height":"50vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.misrLocations,"items-per-page":100,"hide-default-footer":"","loading":!_vm.misrLocations.length,"loading-text":"جاري التحميل"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.misurata = false}}},[_vm._v(" Done ")])],1)],1)],1)],1)]),_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"color":"blue darken-4","dark":""}},'v-btn',attrs,false),on),[_vm._v(" سبها ")])]}}]),model:{value:(_vm.sebha),callback:function ($$v) {_vm.sebha=$$v},expression:"sebha"}},[_c('v-card',{staticClass:"fontCairo"},[_c('v-card-title',{staticClass:"text-h5 blue darken-4 text-center"},[_c('div',{style:({ color: ' #ffff' })},[_vm._v("Sebha Locations")])]),_c('v-layout',{staticStyle:{"height":"50vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sebhaLocations,"items-per-page":100,"hide-default-footer":"","loading":!_vm.sebhaLocations.length,"loading-text":"جاري التحميل"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.sebha = false}}},[_vm._v(" Done ")])],1)],1)],1)],1)])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"color":"blue darken-4","dark":""}},'v-btn',attrs,false),on),[_vm._v(" الزاوية ")])]}}]),model:{value:(_vm.zawia),callback:function ($$v) {_vm.zawia=$$v},expression:"zawia"}},[_c('v-card',{staticClass:"fontCairo"},[_c('v-card-title',{staticClass:"text-h5 blue darken-4 text-center"},[_c('div',{style:({ color: ' #ffff' })},[_vm._v("Zawia Locations")])]),_c('v-layout',{staticStyle:{"height":"50vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.zawiaLocations,"items-per-page":100,"hide-default-footer":"","loading":!_vm.zawiaLocations.length,"loading-text":"جاري التحميل"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.zawia = false}}},[_vm._v(" Done ")])],1)],1)],1)],1)]),_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"color":"blue darken-4","dark":""}},'v-btn',attrs,false),on),[_vm._v(" زليتن ")])]}}]),model:{value:(_vm.zliten),callback:function ($$v) {_vm.zliten=$$v},expression:"zliten"}},[_c('v-card',{staticClass:"fontCairo"},[_c('v-card-title',{staticClass:"text-h5 blue darken-4 text-center"},[_c('div',{style:({ color: ' #ffff' })},[_vm._v("Zliten Locations")])]),_c('v-layout',{staticStyle:{"height":"50vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.zltnLocations,"items-per-page":100,"hide-default-footer":"","loading":!_vm.zltnLocations.length,"loading-text":"جاري التحميل"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.zliten = false}}},[_vm._v(" Done ")])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }